import React from "react";
import cx from "classnames";
import { graphql, PageProps } from "gatsby";

import {
  Hero,
  FaqWidget,
  Resources,
  GetStarted,
  QualitiesList,
} from "components/design-system";
import SEO from "components/SEO";

import { Card } from "components/design-system";
import { Developers, Features, Header } from "components/internet";

import { INTERNET_COMPANIES_DATA } from "data/pages";

import * as styles from "./internet.module.scss";
import * as storiesStyles from "components/customers/Stories/Stories.module.scss";

import HeroGIF from "assets/media/gifs/internet/Hero.gif";
import pagesMeta from "data/pagesMeta";

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;
  return (
    <>
      <SEO {...pagesMeta.internetCompanies} />
      <Hero
        backgroundImage={HeroGIF}
        title="A programmable current account that scales with your startup"
        subtitle="Whether you are in the early or rapid growth stage, we have created a better current account that is customisable to fit your financial operations."
        useOptimizedBackground={false}
      />

      <Features />

      <Resources
        items={pageData.resources}
        title="We have only just scratched the surface"
        description={
          "With a line of products on our roadmap, you can keep doing your best <br /> work while we work on the tools and services you need."
        }
        align="center"
        overflow={false}
      />

      <Developers />

      <Header
        title="The new banking infrastructure for today’s fast businesses"
        body="We help build unique API solutions to cater for the needs of your business. Hear what these businesses have to say."
      >
        <div className={storiesStyles.Stories_container}>
          <div className={storiesStyles.Stories_content}>
            {INTERNET_COMPANIES_DATA.STORIES.map((story, index) => (
              <Card
                key={index}
                customStyles={cx(
                  storiesStyles.Stories_card,
                  storiesStyles.Stories_card_theme_dark
                )}
              >
                <div className={storiesStyles.Stories_card_logo}>
                  <img src={story.image} alt={story.logo} />
                </div>

                <p className={storiesStyles.Stories_card_text}>
                  “{story.story}“
                </p>
                <div className={storiesStyles.Stories_card_meta}>
                  <div className={storiesStyles.Stories_card_avatar}>
                    <img src={story.profile} alt="" />
                  </div>
                  <div>
                    <h3 className={storiesStyles.Stories_card_title}>
                      {story.name}
                    </h3>
                    <p className={storiesStyles.Stories_card_role}>
                      {story.title}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Header>

      <FaqWidget content={pageData.faqContent} />

      <QualitiesList
        theme="dark"
        className={styles.Internet_qualitiesList}
        items={INTERNET_COMPANIES_DATA.QUALITIES}
      />

      <GetStarted />
    </>
  );
};

export const pageQuery = graphql`
  query InternetCompaniesPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "internet-companies" } } }
    ) {
      edges {
        node {
          frontmatter {
            supportItems {
              description
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }

            resources {
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }

            faqContent {
              answer
              question
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

import * as styles from "./Features.module.scss";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { INTERNET_COMPANIES_DATA } from "data/pages";
import React from "react";
import cx from "classnames";

const Features: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <section className={styles.Features}>
      <div className={styles.Features_container}>
        <header className={styles.Features_header}>
          <h2 className={styles.Features_header_title}>
            More features, less <strike>fees</strike>
          </h2>
          <p>
            To enable you track and focus on the growth of your business, we
            have built top-end financial products that allows you to stay on top
            of your finances.
          </p>
        </header>
        <Tabs
          selectedTabPanelClassName={cx(
            styles.Features_panel,
            styles.Features_panel_selected
          )}
          selectedIndex={tabIndex}
          onSelect={(index: number) => setTabIndex(index)}
          disabledTabClassName={styles.Features_panel}
          selectedTabClassName={styles.Features_item_selected}
        >
          <TabList className={styles.Features_list}>
            {INTERNET_COMPANIES_DATA.FEATURES.map((item, index) => (
              <Tab key={index} className={styles.Features_item}>
                <h3 className={styles.Features_item_title}>{item.title}</h3>
              </Tab>
            ))}
          </TabList>

          {INTERNET_COMPANIES_DATA.FEATURES.map((item, index) => (
            <TabPanel key={index}>
              <div className={styles.Features_panel_content}>
                <h3 className={styles.Features_panel_title}>{item.title}</h3>
                <p className={styles.Features_panel_text}>{item.description}</p>
              </div>
              <div className={styles.Features_selectors}>
                {INTERNET_COMPANIES_DATA.FEATURES.map((item, index) => (
                  <div
                    key={index}
                    className={cx(styles.Features_selector, {
                      [styles.Features_selector_selected]: index === tabIndex,
                    })}
                    onClick={() => setTabIndex(index)}
                  ></div>
                ))}
              </div>
              <div className={styles.Features_panel_media}>
                <video autoPlay={true} loop muted src={item.video}></video>
              </div>
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </section>
  );
};

export default Features;

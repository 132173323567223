import { BrandArrow } from "assets/media/svgs";
import { Link } from "gatsby";
import React from "react";

import ImgCodeSnippet from "assets/media/images/internet/code.png";

import * as styles from "./Developers.module.scss";
import { BRASS_API_DOCS_URL } from "data/config";

const Developers: React.FC = () => {
  return (
    <section className={styles.Developers}>
      <div className={styles.Developers_container}>
        <header className={styles.Developers_header}>
          <h2 className={styles.Developers_header_title}>
            Take control of your financial operations
          </h2>
          <p className={styles.Developers_header_body}>
            With our composable API, design your own dashboard or your payment
            and approval flows, complete with technical support when you need
            it.
          </p>
          <a href={BRASS_API_DOCS_URL} target="_blank">
            Explore our API documentation <BrandArrow />
          </a>
        </header>
        <div className={styles.Developers_code}>
          <img src={ImgCodeSnippet} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Developers;

// extracted by mini-css-extract-plugin
export var Developers = "Developers-module--Developers--ItUjM";
export var Developers_code = "Developers-module--Developers_code--NVRl+";
export var Developers_container = "Developers-module--Developers_container--U8nG9";
export var Developers_header = "Developers-module--Developers_header--aUWvL";
export var Developers_header_body = "Developers-module--Developers_header_body--xv50+";
export var Developers_header_title = "Developers-module--Developers_header_title--Zsert";
export var container = "Developers-module--container--ABlGL";
export var gatsbyImageWrapperConstrained = "Developers-module--gatsby-image-wrapper-constrained--cD3ps";
export var gridSpan1010 = "Developers-module--grid-span-10-10--VYm0Q";
export var gridSpan1011 = "Developers-module--grid-span-10-11--s1GJ7";
export var gridSpan102 = "Developers-module--grid-span-10-2--6fH6s";
export var gridSpan103 = "Developers-module--grid-span-10-3--hCN8j";
export var gridSpan104 = "Developers-module--grid-span-10-4--9gZLg";
export var gridSpan105 = "Developers-module--grid-span-10-5--t+Lgf";
export var gridSpan106 = "Developers-module--grid-span-10-6--xCbtg";
export var gridSpan107 = "Developers-module--grid-span-10-7--KgxZQ";
export var gridSpan108 = "Developers-module--grid-span-10-8--nEWB1";
export var gridSpan109 = "Developers-module--grid-span-10-9--Uh54f";
export var gridSpan110 = "Developers-module--grid-span-1-10--sV-R2";
export var gridSpan111 = "Developers-module--grid-span-1-11--6pYCV";
export var gridSpan1110 = "Developers-module--grid-span-11-10--1sSBF";
export var gridSpan1111 = "Developers-module--grid-span-11-11--tl3xp";
export var gridSpan112 = "Developers-module--grid-span-11-2--A8A88";
export var gridSpan113 = "Developers-module--grid-span-11-3--zatyh";
export var gridSpan114 = "Developers-module--grid-span-11-4--BW8rW";
export var gridSpan115 = "Developers-module--grid-span-11-5--2XqHU";
export var gridSpan116 = "Developers-module--grid-span-11-6--F5Lbc";
export var gridSpan117 = "Developers-module--grid-span-11-7--8zen8";
export var gridSpan118 = "Developers-module--grid-span-11-8--ZV4iu";
export var gridSpan119 = "Developers-module--grid-span-11-9--fV9ho";
export var gridSpan12 = "Developers-module--grid-span-1-2--azDvN";
export var gridSpan1210 = "Developers-module--grid-span-12-10--TS6IW";
export var gridSpan1211 = "Developers-module--grid-span-12-11--j9n8+";
export var gridSpan122 = "Developers-module--grid-span-12-2--lE0oP";
export var gridSpan123 = "Developers-module--grid-span-12-3--VBkKn";
export var gridSpan124 = "Developers-module--grid-span-12-4--MBS0H";
export var gridSpan125 = "Developers-module--grid-span-12-5--a7-kN";
export var gridSpan126 = "Developers-module--grid-span-12-6--Ic5tq";
export var gridSpan127 = "Developers-module--grid-span-12-7--MMoQT";
export var gridSpan128 = "Developers-module--grid-span-12-8--4mQN7";
export var gridSpan129 = "Developers-module--grid-span-12-9--yeEGV";
export var gridSpan13 = "Developers-module--grid-span-1-3--SwWta";
export var gridSpan14 = "Developers-module--grid-span-1-4--buXCH";
export var gridSpan15 = "Developers-module--grid-span-1-5--7l-Ha";
export var gridSpan16 = "Developers-module--grid-span-1-6--dgJiB";
export var gridSpan17 = "Developers-module--grid-span-1-7--7rm-w";
export var gridSpan18 = "Developers-module--grid-span-1-8--Dh1c3";
export var gridSpan19 = "Developers-module--grid-span-1-9--al+Xs";
export var gridSpan210 = "Developers-module--grid-span-2-10--vQqc7";
export var gridSpan211 = "Developers-module--grid-span-2-11--kRJBC";
export var gridSpan22 = "Developers-module--grid-span-2-2--pFBIU";
export var gridSpan23 = "Developers-module--grid-span-2-3--pgbbm";
export var gridSpan24 = "Developers-module--grid-span-2-4--ad8nm";
export var gridSpan25 = "Developers-module--grid-span-2-5--NWwbn";
export var gridSpan26 = "Developers-module--grid-span-2-6--cAJrY";
export var gridSpan27 = "Developers-module--grid-span-2-7--0Srmz";
export var gridSpan28 = "Developers-module--grid-span-2-8--FVi+-";
export var gridSpan29 = "Developers-module--grid-span-2-9--AgHqg";
export var gridSpan310 = "Developers-module--grid-span-3-10--4RIoj";
export var gridSpan311 = "Developers-module--grid-span-3-11--X8cJC";
export var gridSpan32 = "Developers-module--grid-span-3-2--yx9JQ";
export var gridSpan33 = "Developers-module--grid-span-3-3--NhfzK";
export var gridSpan34 = "Developers-module--grid-span-3-4--F9EIa";
export var gridSpan35 = "Developers-module--grid-span-3-5--2PAmg";
export var gridSpan36 = "Developers-module--grid-span-3-6--6FQfS";
export var gridSpan37 = "Developers-module--grid-span-3-7--agubq";
export var gridSpan38 = "Developers-module--grid-span-3-8--H5yGD";
export var gridSpan39 = "Developers-module--grid-span-3-9--ekgvU";
export var gridSpan410 = "Developers-module--grid-span-4-10--gRamb";
export var gridSpan411 = "Developers-module--grid-span-4-11--Grrwz";
export var gridSpan42 = "Developers-module--grid-span-4-2--8Au80";
export var gridSpan43 = "Developers-module--grid-span-4-3---dTW7";
export var gridSpan44 = "Developers-module--grid-span-4-4--2FbiQ";
export var gridSpan45 = "Developers-module--grid-span-4-5--+1Lvr";
export var gridSpan46 = "Developers-module--grid-span-4-6--OwhSg";
export var gridSpan47 = "Developers-module--grid-span-4-7--16URw";
export var gridSpan48 = "Developers-module--grid-span-4-8--wGW3b";
export var gridSpan49 = "Developers-module--grid-span-4-9--FIIUM";
export var gridSpan510 = "Developers-module--grid-span-5-10--SqukK";
export var gridSpan511 = "Developers-module--grid-span-5-11--4Fa3b";
export var gridSpan52 = "Developers-module--grid-span-5-2--ZA37q";
export var gridSpan53 = "Developers-module--grid-span-5-3--E7zug";
export var gridSpan54 = "Developers-module--grid-span-5-4--BOWY8";
export var gridSpan55 = "Developers-module--grid-span-5-5--V+5n6";
export var gridSpan56 = "Developers-module--grid-span-5-6--taGhe";
export var gridSpan57 = "Developers-module--grid-span-5-7--o5G5u";
export var gridSpan58 = "Developers-module--grid-span-5-8--qTYYf";
export var gridSpan59 = "Developers-module--grid-span-5-9--AAnnb";
export var gridSpan610 = "Developers-module--grid-span-6-10--zNNpj";
export var gridSpan611 = "Developers-module--grid-span-6-11--nhabY";
export var gridSpan62 = "Developers-module--grid-span-6-2--ntTzt";
export var gridSpan63 = "Developers-module--grid-span-6-3--HGOHK";
export var gridSpan64 = "Developers-module--grid-span-6-4--oa2dD";
export var gridSpan65 = "Developers-module--grid-span-6-5--gXosA";
export var gridSpan66 = "Developers-module--grid-span-6-6--nFiZ9";
export var gridSpan67 = "Developers-module--grid-span-6-7--pIdK8";
export var gridSpan68 = "Developers-module--grid-span-6-8--Op8go";
export var gridSpan69 = "Developers-module--grid-span-6-9--IbeaN";
export var gridSpan710 = "Developers-module--grid-span-7-10--JPMwP";
export var gridSpan711 = "Developers-module--grid-span-7-11--ZahWc";
export var gridSpan72 = "Developers-module--grid-span-7-2--XKJp7";
export var gridSpan73 = "Developers-module--grid-span-7-3--PnEtq";
export var gridSpan74 = "Developers-module--grid-span-7-4--RhcVC";
export var gridSpan75 = "Developers-module--grid-span-7-5--MSe9X";
export var gridSpan76 = "Developers-module--grid-span-7-6--i6K5X";
export var gridSpan77 = "Developers-module--grid-span-7-7--CCTs-";
export var gridSpan78 = "Developers-module--grid-span-7-8--LfoYX";
export var gridSpan79 = "Developers-module--grid-span-7-9--O-AyT";
export var gridSpan810 = "Developers-module--grid-span-8-10--MLF7m";
export var gridSpan811 = "Developers-module--grid-span-8-11--WDeO7";
export var gridSpan82 = "Developers-module--grid-span-8-2--9vEFu";
export var gridSpan83 = "Developers-module--grid-span-8-3--lDzFx";
export var gridSpan84 = "Developers-module--grid-span-8-4--9aBJN";
export var gridSpan85 = "Developers-module--grid-span-8-5--G-7D0";
export var gridSpan86 = "Developers-module--grid-span-8-6--6zSHJ";
export var gridSpan87 = "Developers-module--grid-span-8-7--Owhsz";
export var gridSpan88 = "Developers-module--grid-span-8-8--6Oa6e";
export var gridSpan89 = "Developers-module--grid-span-8-9--GBGQC";
export var gridSpan910 = "Developers-module--grid-span-9-10--P+Rf+";
export var gridSpan911 = "Developers-module--grid-span-9-11--CojoS";
export var gridSpan92 = "Developers-module--grid-span-9-2--ARAkv";
export var gridSpan93 = "Developers-module--grid-span-9-3--LHg7Y";
export var gridSpan94 = "Developers-module--grid-span-9-4--M43kf";
export var gridSpan95 = "Developers-module--grid-span-9-5--AEOTS";
export var gridSpan96 = "Developers-module--grid-span-9-6--GKnIZ";
export var gridSpan97 = "Developers-module--grid-span-9-7--tabf1";
export var gridSpan98 = "Developers-module--grid-span-9-8--uEttJ";
export var gridSpan99 = "Developers-module--grid-span-9-9--3qCiE";
export var textBreak = "Developers-module--text-break--lC9RQ";
// extracted by mini-css-extract-plugin
export var Stories = "Stories-module--Stories--VDOBQ";
export var Stories_card = "Stories-module--Stories_card--CS7PW";
export var Stories_card_avatar = "Stories-module--Stories_card_avatar--vLWAG";
export var Stories_card_logo = "Stories-module--Stories_card_logo--jUe2f";
export var Stories_card_meta = "Stories-module--Stories_card_meta--gGXLL";
export var Stories_card_role = "Stories-module--Stories_card_role--o97p-";
export var Stories_card_text = "Stories-module--Stories_card_text--fW-id";
export var Stories_card_theme_dark = "Stories-module--Stories_card_theme_dark--GvNkF";
export var Stories_card_title = "Stories-module--Stories_card_title--VrIU6";
export var Stories_container = "Stories-module--Stories_container--TkOFt";
export var Stories_content = "Stories-module--Stories_content--YRwh5";
export var Stories_header = "Stories-module--Stories_header--Y3l2Z";
export var Stories_header_title = "Stories-module--Stories_header_title--cGo0a";
export var container = "Stories-module--container--L4C76";
export var gatsbyImageWrapperConstrained = "Stories-module--gatsby-image-wrapper-constrained--64pzH";
export var gridSpan1010 = "Stories-module--grid-span-10-10--56GXi";
export var gridSpan1011 = "Stories-module--grid-span-10-11--iul4U";
export var gridSpan102 = "Stories-module--grid-span-10-2--wUZNq";
export var gridSpan103 = "Stories-module--grid-span-10-3--1WSMf";
export var gridSpan104 = "Stories-module--grid-span-10-4--rE0OR";
export var gridSpan105 = "Stories-module--grid-span-10-5--bZhrq";
export var gridSpan106 = "Stories-module--grid-span-10-6--pflJ5";
export var gridSpan107 = "Stories-module--grid-span-10-7--MdTpP";
export var gridSpan108 = "Stories-module--grid-span-10-8--JcQ0q";
export var gridSpan109 = "Stories-module--grid-span-10-9--ye9u6";
export var gridSpan110 = "Stories-module--grid-span-1-10--rCuYh";
export var gridSpan111 = "Stories-module--grid-span-1-11--bu5H2";
export var gridSpan1110 = "Stories-module--grid-span-11-10--21tNe";
export var gridSpan1111 = "Stories-module--grid-span-11-11--RYneJ";
export var gridSpan112 = "Stories-module--grid-span-11-2--UhwZc";
export var gridSpan113 = "Stories-module--grid-span-11-3--gxYan";
export var gridSpan114 = "Stories-module--grid-span-11-4--rm3Zr";
export var gridSpan115 = "Stories-module--grid-span-11-5--8Il-g";
export var gridSpan116 = "Stories-module--grid-span-11-6--lo46w";
export var gridSpan117 = "Stories-module--grid-span-11-7--1y8uZ";
export var gridSpan118 = "Stories-module--grid-span-11-8--Xo5Gy";
export var gridSpan119 = "Stories-module--grid-span-11-9--n-5yW";
export var gridSpan12 = "Stories-module--grid-span-1-2--aEs4A";
export var gridSpan1210 = "Stories-module--grid-span-12-10--Yfp1o";
export var gridSpan1211 = "Stories-module--grid-span-12-11--MmzZK";
export var gridSpan122 = "Stories-module--grid-span-12-2--YmJi9";
export var gridSpan123 = "Stories-module--grid-span-12-3--dda0G";
export var gridSpan124 = "Stories-module--grid-span-12-4--MhRpY";
export var gridSpan125 = "Stories-module--grid-span-12-5--vPuwu";
export var gridSpan126 = "Stories-module--grid-span-12-6--KGHDY";
export var gridSpan127 = "Stories-module--grid-span-12-7--2MjZB";
export var gridSpan128 = "Stories-module--grid-span-12-8--gqPzU";
export var gridSpan129 = "Stories-module--grid-span-12-9--uwD5b";
export var gridSpan13 = "Stories-module--grid-span-1-3--yli++";
export var gridSpan14 = "Stories-module--grid-span-1-4--LyvMM";
export var gridSpan15 = "Stories-module--grid-span-1-5--eXpcT";
export var gridSpan16 = "Stories-module--grid-span-1-6--Yo6j8";
export var gridSpan17 = "Stories-module--grid-span-1-7--VbwO+";
export var gridSpan18 = "Stories-module--grid-span-1-8--UgKSf";
export var gridSpan19 = "Stories-module--grid-span-1-9--cChWX";
export var gridSpan210 = "Stories-module--grid-span-2-10--GHIsJ";
export var gridSpan211 = "Stories-module--grid-span-2-11--K6eMy";
export var gridSpan22 = "Stories-module--grid-span-2-2--hm0so";
export var gridSpan23 = "Stories-module--grid-span-2-3--tIREL";
export var gridSpan24 = "Stories-module--grid-span-2-4--fnFt6";
export var gridSpan25 = "Stories-module--grid-span-2-5--hcUlE";
export var gridSpan26 = "Stories-module--grid-span-2-6--9LiVH";
export var gridSpan27 = "Stories-module--grid-span-2-7--D+ARa";
export var gridSpan28 = "Stories-module--grid-span-2-8--eZNp1";
export var gridSpan29 = "Stories-module--grid-span-2-9--wiJsq";
export var gridSpan310 = "Stories-module--grid-span-3-10--bm+zn";
export var gridSpan311 = "Stories-module--grid-span-3-11--ip37m";
export var gridSpan32 = "Stories-module--grid-span-3-2--Doi-V";
export var gridSpan33 = "Stories-module--grid-span-3-3--g3N2X";
export var gridSpan34 = "Stories-module--grid-span-3-4--k4Sg7";
export var gridSpan35 = "Stories-module--grid-span-3-5--2LKIh";
export var gridSpan36 = "Stories-module--grid-span-3-6--vI44g";
export var gridSpan37 = "Stories-module--grid-span-3-7--OLhhK";
export var gridSpan38 = "Stories-module--grid-span-3-8--o0uGn";
export var gridSpan39 = "Stories-module--grid-span-3-9--DH26a";
export var gridSpan410 = "Stories-module--grid-span-4-10--Qm24f";
export var gridSpan411 = "Stories-module--grid-span-4-11--ELBM9";
export var gridSpan42 = "Stories-module--grid-span-4-2--dpx-o";
export var gridSpan43 = "Stories-module--grid-span-4-3--c+l4D";
export var gridSpan44 = "Stories-module--grid-span-4-4--7L+St";
export var gridSpan45 = "Stories-module--grid-span-4-5--zbH-p";
export var gridSpan46 = "Stories-module--grid-span-4-6--MNWVA";
export var gridSpan47 = "Stories-module--grid-span-4-7--A7rZb";
export var gridSpan48 = "Stories-module--grid-span-4-8--UeL-I";
export var gridSpan49 = "Stories-module--grid-span-4-9--N3bJA";
export var gridSpan510 = "Stories-module--grid-span-5-10--7p2F1";
export var gridSpan511 = "Stories-module--grid-span-5-11--Pb+H0";
export var gridSpan52 = "Stories-module--grid-span-5-2--7lAWe";
export var gridSpan53 = "Stories-module--grid-span-5-3--nnShV";
export var gridSpan54 = "Stories-module--grid-span-5-4--B0VRk";
export var gridSpan55 = "Stories-module--grid-span-5-5--lyQEh";
export var gridSpan56 = "Stories-module--grid-span-5-6--jpi0Y";
export var gridSpan57 = "Stories-module--grid-span-5-7--BlFlP";
export var gridSpan58 = "Stories-module--grid-span-5-8--z5e+X";
export var gridSpan59 = "Stories-module--grid-span-5-9--lvTDP";
export var gridSpan610 = "Stories-module--grid-span-6-10--CDSAa";
export var gridSpan611 = "Stories-module--grid-span-6-11--J9jzZ";
export var gridSpan62 = "Stories-module--grid-span-6-2--WMbvD";
export var gridSpan63 = "Stories-module--grid-span-6-3--jzFop";
export var gridSpan64 = "Stories-module--grid-span-6-4--zb8xh";
export var gridSpan65 = "Stories-module--grid-span-6-5--E9Fgv";
export var gridSpan66 = "Stories-module--grid-span-6-6--9Wbjy";
export var gridSpan67 = "Stories-module--grid-span-6-7--7g78E";
export var gridSpan68 = "Stories-module--grid-span-6-8--zc-f-";
export var gridSpan69 = "Stories-module--grid-span-6-9--PrayJ";
export var gridSpan710 = "Stories-module--grid-span-7-10--9THuR";
export var gridSpan711 = "Stories-module--grid-span-7-11--PPAL9";
export var gridSpan72 = "Stories-module--grid-span-7-2--G2FVb";
export var gridSpan73 = "Stories-module--grid-span-7-3--0pIYT";
export var gridSpan74 = "Stories-module--grid-span-7-4--sTn3Q";
export var gridSpan75 = "Stories-module--grid-span-7-5--8uZIY";
export var gridSpan76 = "Stories-module--grid-span-7-6--rpQAX";
export var gridSpan77 = "Stories-module--grid-span-7-7--OaqtT";
export var gridSpan78 = "Stories-module--grid-span-7-8--3tQcS";
export var gridSpan79 = "Stories-module--grid-span-7-9--Kpfr0";
export var gridSpan810 = "Stories-module--grid-span-8-10--+306b";
export var gridSpan811 = "Stories-module--grid-span-8-11--nhKEj";
export var gridSpan82 = "Stories-module--grid-span-8-2--5YtTM";
export var gridSpan83 = "Stories-module--grid-span-8-3--xOxyF";
export var gridSpan84 = "Stories-module--grid-span-8-4--y1l9B";
export var gridSpan85 = "Stories-module--grid-span-8-5--2uxiP";
export var gridSpan86 = "Stories-module--grid-span-8-6--d3PA5";
export var gridSpan87 = "Stories-module--grid-span-8-7--mQszy";
export var gridSpan88 = "Stories-module--grid-span-8-8--Jy4yl";
export var gridSpan89 = "Stories-module--grid-span-8-9--tvP27";
export var gridSpan910 = "Stories-module--grid-span-9-10--xJkjj";
export var gridSpan911 = "Stories-module--grid-span-9-11--LWxo8";
export var gridSpan92 = "Stories-module--grid-span-9-2--vQyem";
export var gridSpan93 = "Stories-module--grid-span-9-3--LZDJy";
export var gridSpan94 = "Stories-module--grid-span-9-4--GV7cn";
export var gridSpan95 = "Stories-module--grid-span-9-5--zvx4y";
export var gridSpan96 = "Stories-module--grid-span-9-6--sZ8F2";
export var gridSpan97 = "Stories-module--grid-span-9-7--cfON3";
export var gridSpan98 = "Stories-module--grid-span-9-8--70un0";
export var gridSpan99 = "Stories-module--grid-span-9-9--9+xFf";
export var textBreak = "Stories-module--text-break--yOYfD";
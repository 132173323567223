// extracted by mini-css-extract-plugin
export var Internet_header_body = "internet-module--Internet_header_body--nYByA";
export var Internet_qualitiesList = "internet-module--Internet_qualitiesList--hgqBt";
export var Internet_theme_dark = "internet-module--Internet_theme_dark--g3Iae";
export var container = "internet-module--container--doRGM";
export var gatsbyImageWrapperConstrained = "internet-module--gatsby-image-wrapper-constrained--CRxyh";
export var gridSpan1010 = "internet-module--grid-span-10-10--McGi4";
export var gridSpan1011 = "internet-module--grid-span-10-11--vekRu";
export var gridSpan102 = "internet-module--grid-span-10-2--esvMv";
export var gridSpan103 = "internet-module--grid-span-10-3--sVdrT";
export var gridSpan104 = "internet-module--grid-span-10-4--g9BTC";
export var gridSpan105 = "internet-module--grid-span-10-5--qdZM1";
export var gridSpan106 = "internet-module--grid-span-10-6--0xyDO";
export var gridSpan107 = "internet-module--grid-span-10-7--aXvVJ";
export var gridSpan108 = "internet-module--grid-span-10-8--WUzc7";
export var gridSpan109 = "internet-module--grid-span-10-9--9wmMv";
export var gridSpan110 = "internet-module--grid-span-1-10--kiL-u";
export var gridSpan111 = "internet-module--grid-span-1-11--uSbck";
export var gridSpan1110 = "internet-module--grid-span-11-10--PeswR";
export var gridSpan1111 = "internet-module--grid-span-11-11--tjalX";
export var gridSpan112 = "internet-module--grid-span-11-2--y1Cno";
export var gridSpan113 = "internet-module--grid-span-11-3--7D0tt";
export var gridSpan114 = "internet-module--grid-span-11-4--bDypS";
export var gridSpan115 = "internet-module--grid-span-11-5--foBMe";
export var gridSpan116 = "internet-module--grid-span-11-6--e+rQU";
export var gridSpan117 = "internet-module--grid-span-11-7--XmeB8";
export var gridSpan118 = "internet-module--grid-span-11-8--vQ9ng";
export var gridSpan119 = "internet-module--grid-span-11-9--PFYyO";
export var gridSpan12 = "internet-module--grid-span-1-2--zVxzO";
export var gridSpan1210 = "internet-module--grid-span-12-10--W+akv";
export var gridSpan1211 = "internet-module--grid-span-12-11--Xbllt";
export var gridSpan122 = "internet-module--grid-span-12-2--bXy5r";
export var gridSpan123 = "internet-module--grid-span-12-3--jRu-V";
export var gridSpan124 = "internet-module--grid-span-12-4--iihgp";
export var gridSpan125 = "internet-module--grid-span-12-5--f+8Dk";
export var gridSpan126 = "internet-module--grid-span-12-6--XTQe7";
export var gridSpan127 = "internet-module--grid-span-12-7--IMXMy";
export var gridSpan128 = "internet-module--grid-span-12-8--6rEOC";
export var gridSpan129 = "internet-module--grid-span-12-9--7PXVv";
export var gridSpan13 = "internet-module--grid-span-1-3--jgrWc";
export var gridSpan14 = "internet-module--grid-span-1-4--Nl-IN";
export var gridSpan15 = "internet-module--grid-span-1-5--RAeNx";
export var gridSpan16 = "internet-module--grid-span-1-6--Z90M2";
export var gridSpan17 = "internet-module--grid-span-1-7--uJP1Y";
export var gridSpan18 = "internet-module--grid-span-1-8--z6JXR";
export var gridSpan19 = "internet-module--grid-span-1-9--B4kJO";
export var gridSpan210 = "internet-module--grid-span-2-10--TeNnr";
export var gridSpan211 = "internet-module--grid-span-2-11--N6tZw";
export var gridSpan22 = "internet-module--grid-span-2-2--FJ8mu";
export var gridSpan23 = "internet-module--grid-span-2-3--pdAYL";
export var gridSpan24 = "internet-module--grid-span-2-4--ceUA-";
export var gridSpan25 = "internet-module--grid-span-2-5--v32uf";
export var gridSpan26 = "internet-module--grid-span-2-6--AoVy0";
export var gridSpan27 = "internet-module--grid-span-2-7--AN5kb";
export var gridSpan28 = "internet-module--grid-span-2-8--Ei6+d";
export var gridSpan29 = "internet-module--grid-span-2-9--zUVk4";
export var gridSpan310 = "internet-module--grid-span-3-10--aBiNo";
export var gridSpan311 = "internet-module--grid-span-3-11--NBxCi";
export var gridSpan32 = "internet-module--grid-span-3-2--SBuli";
export var gridSpan33 = "internet-module--grid-span-3-3--bjVZT";
export var gridSpan34 = "internet-module--grid-span-3-4--vnueu";
export var gridSpan35 = "internet-module--grid-span-3-5--N-kn-";
export var gridSpan36 = "internet-module--grid-span-3-6--3RvcF";
export var gridSpan37 = "internet-module--grid-span-3-7--OfrAV";
export var gridSpan38 = "internet-module--grid-span-3-8--kAJ48";
export var gridSpan39 = "internet-module--grid-span-3-9--L19Rm";
export var gridSpan410 = "internet-module--grid-span-4-10--IINcu";
export var gridSpan411 = "internet-module--grid-span-4-11--P6Fl9";
export var gridSpan42 = "internet-module--grid-span-4-2--OY4qj";
export var gridSpan43 = "internet-module--grid-span-4-3--Trci6";
export var gridSpan44 = "internet-module--grid-span-4-4--2C0pW";
export var gridSpan45 = "internet-module--grid-span-4-5--0bCYC";
export var gridSpan46 = "internet-module--grid-span-4-6--GtpZr";
export var gridSpan47 = "internet-module--grid-span-4-7--7tkkh";
export var gridSpan48 = "internet-module--grid-span-4-8--Xcruj";
export var gridSpan49 = "internet-module--grid-span-4-9--jO1AU";
export var gridSpan510 = "internet-module--grid-span-5-10--tJSwy";
export var gridSpan511 = "internet-module--grid-span-5-11--qHZzT";
export var gridSpan52 = "internet-module--grid-span-5-2--qFIvj";
export var gridSpan53 = "internet-module--grid-span-5-3--nLdnr";
export var gridSpan54 = "internet-module--grid-span-5-4--k3lBf";
export var gridSpan55 = "internet-module--grid-span-5-5--StaPU";
export var gridSpan56 = "internet-module--grid-span-5-6--b9Eh0";
export var gridSpan57 = "internet-module--grid-span-5-7--PLUpr";
export var gridSpan58 = "internet-module--grid-span-5-8--BKq9q";
export var gridSpan59 = "internet-module--grid-span-5-9--2+kfA";
export var gridSpan610 = "internet-module--grid-span-6-10--WlZEg";
export var gridSpan611 = "internet-module--grid-span-6-11--9aVQG";
export var gridSpan62 = "internet-module--grid-span-6-2--Tpr8m";
export var gridSpan63 = "internet-module--grid-span-6-3--dyEyf";
export var gridSpan64 = "internet-module--grid-span-6-4--7tQ0C";
export var gridSpan65 = "internet-module--grid-span-6-5--VdPAb";
export var gridSpan66 = "internet-module--grid-span-6-6--2xQjm";
export var gridSpan67 = "internet-module--grid-span-6-7--CZAVY";
export var gridSpan68 = "internet-module--grid-span-6-8--GGp3T";
export var gridSpan69 = "internet-module--grid-span-6-9--4PLgy";
export var gridSpan710 = "internet-module--grid-span-7-10--pqTFx";
export var gridSpan711 = "internet-module--grid-span-7-11--pL2PC";
export var gridSpan72 = "internet-module--grid-span-7-2--kUt-x";
export var gridSpan73 = "internet-module--grid-span-7-3--wDFup";
export var gridSpan74 = "internet-module--grid-span-7-4--q-fiP";
export var gridSpan75 = "internet-module--grid-span-7-5--xbz5K";
export var gridSpan76 = "internet-module--grid-span-7-6--kSQcs";
export var gridSpan77 = "internet-module--grid-span-7-7--zcgMt";
export var gridSpan78 = "internet-module--grid-span-7-8--fB8q0";
export var gridSpan79 = "internet-module--grid-span-7-9--U5+PV";
export var gridSpan810 = "internet-module--grid-span-8-10--s07HK";
export var gridSpan811 = "internet-module--grid-span-8-11--3apf+";
export var gridSpan82 = "internet-module--grid-span-8-2--3MBdm";
export var gridSpan83 = "internet-module--grid-span-8-3--kh2xq";
export var gridSpan84 = "internet-module--grid-span-8-4--4NN3N";
export var gridSpan85 = "internet-module--grid-span-8-5--17yKB";
export var gridSpan86 = "internet-module--grid-span-8-6--qJrBZ";
export var gridSpan87 = "internet-module--grid-span-8-7--6pb4X";
export var gridSpan88 = "internet-module--grid-span-8-8--NJSCe";
export var gridSpan89 = "internet-module--grid-span-8-9--cxdov";
export var gridSpan910 = "internet-module--grid-span-9-10--qHUp9";
export var gridSpan911 = "internet-module--grid-span-9-11--g1u8i";
export var gridSpan92 = "internet-module--grid-span-9-2--gu0oL";
export var gridSpan93 = "internet-module--grid-span-9-3--kYdrB";
export var gridSpan94 = "internet-module--grid-span-9-4--DRBZb";
export var gridSpan95 = "internet-module--grid-span-9-5--P4V9e";
export var gridSpan96 = "internet-module--grid-span-9-6--TMbTz";
export var gridSpan97 = "internet-module--grid-span-9-7--Ar2VT";
export var gridSpan98 = "internet-module--grid-span-9-8--Ms9yd";
export var gridSpan99 = "internet-module--grid-span-9-9--S2Bbw";
export var textBreak = "internet-module--text-break--Nl56z";
import React from "react";

import * as styles from "./Header.module.scss";

interface HeaderProps {
  body: string;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ children, title, body }) => {
  return (
    <section className={styles.Header}>
      <div className={styles.Header_container}>
        <header className={styles.Header_header}>
          <h2 className={styles.Header_header_title}>{title}</h2>
          <p className={styles.Header_header_body}>{body}</p>
        </header>
      </div>
      {children}
    </section>
  );
};

export default Header;
